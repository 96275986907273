<template>
  <v-card>
    <v-list style="padding-top:0;">
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in itemsList" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="Markets" :search="search" style="font-size: 5px !important;">
                <template v-slot:item.operations="{ item }">
                  <v-btn @click="TradeDissAllow(item.id)" v-if="item.tradeAllowed === true" style="min-width: 2rem !important;" color="error"> بسته شود</v-btn>
                  <v-btn @click="TradeAllow(item.id)" v-if="item.tradeAllowed === false" style="min-width: 2rem !important;" color="success"> باز شود </v-btn>
                </template>
                <template v-slot:item.tradeAllowed="{ item }">
                  <i :class="[item.tradeAllowed === false ? 'flaticon-check red-check' : 'flaticon-check green-check']"></i>
                </template>
              </v-data-table>
            </v-card>
            <Popup width="600px" :show.sync="newShow">
              <template slot="body">
                <v-toolbar color="primary" dark class="rtl">
                  اضافه کردن بازار جدید
                </v-toolbar>
                <v-card-text class="rtl">
                  <slot name="body"></slot>
                  <div style="margin: 20px">
                    <v-row>
                      <v-text-field outlined label="نام فارسی" v-model="persianTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field outlined label="نام انگلیسی" v-model="englishTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <div class="col-2" style="margin-right: 30px"> کوین مبدا</div>
                      <select class="form-control col-5" v-model="sourceAssetId">
                        <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                        <option v-for="(asset, index) in assets " :key="index" :value="asset.id">{{ asset.symbol }}</option>
                      </select>
                    </v-row>
                    <v-row class="row" style="margin-top:20px">
                      <div class="col-2" style="margin-right: 30px"> کوین مقصد</div>
                      <select class="form-control col-5" v-model="destinationAssetId">
                        <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                        <option v-for="(asset, index) in assets " :key="index" :value="asset.id">{{ asset.symbol }}</option>
                      </select>
                    </v-row>
                    <v-row class="row" style="margin-top:20px">
                      <v-text-field outlined label="نماد" v-model="symbol"></v-text-field>
                    </v-row>

                    <div style="margin-right: 40%">
                      <v-btn color="success" @click="addPairs()">اضافه شود</v-btn>
                    </div>

                    <hr style="border-top-color: white">
                  </div>
                </v-card-text>
              </template>
            </Popup>
            <Popup width="600px" :show.sync="updateShow">
              <template slot="body">
                <v-toolbar color="primary" dark class="rtl">
                  ویرایش بازار
                </v-toolbar>
                <v-card-text class="rtl">
                  <slot name="body"></slot>
                  <div style="margin: 20px">
                    <v-row>
                      <v-text-field outlined label="نام فارسی" v-model="persianTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field outlined label="نام انگلیسی" v-model="englishTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <div class="col-2" style="margin-right: 30px"> کوین مبدا</div>
                      <select class="form-control col-5" v-model="sourceAssetId">
                        <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                        <option v-for="(asset, index) in assets " :key="index" :value="asset.id">{{ asset.symbol }}</option>
                      </select>
                    </v-row>
                    <v-row class="row" style="margin-top:20px">
                      <div class="col-2" style="margin-right: 30px"> کوین مقصد</div>
                      <select class="form-control col-5" v-model="destinationAssetId">
                        <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                        <option v-for="(asset, index) in assets " :key="index" :value="asset.id">{{ asset.symbol }}</option>
                      </select>
                    </v-row>
                    <v-row class="row" style="margin-top:20px">
                      <v-text-field outlined label="نماد" v-model="symbol"></v-text-field>
                    </v-row>

                    <div style="margin-right: 40%">
                      <v-btn color="success" @click="updatePair(id)">ویرایش</v-btn>
                    </div>

                    <hr style="border-top-color: white">
                  </div>
                </v-card-text>
              </template>

            </Popup>

          </div>
        </v-tab-item>
        <v-tab-item>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headersMarkets" :items="MarketAccounts" :search="search">
            <template v-slot:item.operations="{ item }">
              <v-row style="justify-content:center;">
                <v-btn @click="TradeDissAllowMarket(item.id)" v-if="item.tradeAllowed === true" color="error">معاملات بسته شود </v-btn>
                <v-btn @click="TradeAllowMarket(item.id)" v-if="item.tradeAllowed === false" color="success">معاملات باز شود </v-btn>
              </v-row>
            </template>
            <template v-slot:item.tradeAllowed="{ item }">
              <i :class="[item.tradeAllowed === false ? 'flaticon-check red-check' : 'flaticon-check green-check']"></i>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headersFastEx" :items="fastExList.filter((item) => item.symbol != 'TOMAN')" :search="search">
            <template v-slot:item.operations="{ item }">
              <v-row style="justify-content:center;column-gap: 5px;flex-wrap: nowrap;">
                <v-btn
                  color="secondary" @click="showBuyModal(item.assetId)"
                  style=" min-width: 50px !important;"
                  v-if="item.autoSetFastExchangePrice == false"
                >ویرایش خرید</v-btn>
                <v-btn
                  color="secondary" @click="showSellModal(item.assetId)"
                  style=" min-width: 50px !important;"
                  v-if="item.autoSetFastExchangePrice == false"
                >ویرایش فروش</v-btn>
                <v-btn
                  color="secondary" @click="fastExchangeAutoSet(item.assetId, 'true')"
                  v-if="item.autoSetFastExchangePrice == false"
                  style=" min-width: 50px !important;"
                >فعال سازی به روزرسانی قیمت</v-btn>
                <v-btn
                  color="red" @click="fastExchangeAutoSet(item.assetId, 'false')"
                  style=" min-width: 50px !important;color:white;"
                  v-if="item.autoSetFastExchangePrice"
                >غیرفعال سازی به روزرسانی قیمت</v-btn>
              </v-row>
            </template>
            <template v-slot:item.fastBuy="{ item }">
              {{ getFilterArray(item.id, 'Buy') }}
            </template>
            <template v-slot:item.fastSell="{ item }">
              {{ getFilterArray(item.id, 'sell') }}
            </template>
            <template v-slot:item.tradeAllowed="{ item }">
              <i :class="[item.tradeAllowed === false ? 'flaticon-check red-check' : 'flaticon-check green-check']"></i>
            </template>
          </v-data-table>
          <Popup :show.sync="sellShow" width="600px">
            <template slot="body">
              <v-toolbar color="primary" dark class="rtl">ویرایش</v-toolbar>
              <v-card-text class="rtl">
                <slot name="body"></slot>
                <div class="row" style="margin-top:20px">
                  <div class="col-2" style="margin-right: 30px">قیمت فروش</div>
                  <input v-model="fastSell" class="form-control col-5 panelInput">
                </div>

                <div style="margin-right: 35%;margin-top:30px;">
                  <v-btn color="success" @click="SetFastExchangePrice('Sell', fastSell)">ویرایش قیمت </v-btn>
                </div>

                <hr style="border-top-color: white">

              </v-card-text>
            </template>
          </Popup>
          <Popup :show.sync="buyShow" width="600px">
            <template slot="body">
              <v-toolbar color="primary" dark class="rtl">ویرایش</v-toolbar>
              <v-card-text class="rtl">
                <slot name="body"></slot>
                <div class="row" style="margin-top:20px">
                  <div class="col-2" style="margin-right: 30px">قیمت خرید</div>
                  <input v-model="fastBuy" class="form-control col-5 panelInput">
                </div>

                <div style="margin-right: 35%;margin-top:30px;">
                  <v-btn color="success" @click="SetFastExchangePrice('Buy', fastBuy)">ویرایش قیمت </v-btn>
                </div>

                <hr style="border-top-color: white">
              </v-card-text>
            </template>
          </Popup>
        </v-tab-item>
      </v-tabs-items>
    </v-list>
  </v-card>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import Popup from "@/components/Popup";

export default {
  components: { Popup },
  name: "MarketSettings",
  data() {
    return {
      itemsList: [ 'مدیریت بازار ها', 'دریافت اکانت های مارکت', 'تنظیمات معاملات سریع' ],
      Markets: [],
      sourceAssetId: '',
      destinationAssetId: '',
      autoSetFastExchangePrice: false,
      symbol: '',
      persianTitle: '',
      englishTitle: '',
      id: '',
      assets: [],
      newShow: false,
      updateShow: false,
      search: '',
      tab: null,
      headers: [
        {
          text: 'وضعیت ',
          align: 'start',
          sortable: false,
          value: 'tradeAllowed',
        },
        { text: 'آی دی', value: 'id' },
        { text: 'رمز ارز مبدا', value: 'sourceAsset' },
        { text: 'آی دی رمز ارز مبدا', value: 'sourceAssetId' },
        { text: ' رمز ارز مقصد', value: 'destinationAsset' },
        { text: 'آی دی رمز ارز مقصد', value: 'destinationAssetId' },
        { text: 'نماد', value: 'symbol' },
        { text: 'نام فارسی', value: 'persianTitle' },
        { text: 'نام انگلیسی', value: 'englishTitle' },
        { text: 'عملیات', value: 'operations' },
      ],
      MarketAccounts: [],
      headersMarkets: [
        {
          text: 'وضعیت ',
          align: 'start',
          sortable: false,
          value: 'tradeAllowed',
        },
        { text: 'آی دی', value: 'id' },
        { text: 'نام کاربری', value: 'userUsername' },
        { text: 'نوع مارکت', value: 'marketAccountType' },
        { text: 'عملیات', value: 'operations' },
      ],
      headersFastEx: [
        { text: 'آی دی', value: 'assetId' },
        { text: 'نماد', value: 'symbol' },
        { text: 'قیمت فروش', value: 'fastExchangeSellPriceInToman' },
        { text: 'قیمت خرید', value: 'fastExchangeBuyPriceInToman' },
        { text: 'عملیات', value: 'operations' },
      ],
      fastExList: [],
      fastBuy: '',
      fastSell: '',
      sellShow: false,
      buyShow: false,
      arrayPriceFastEx: [],
      arrayPrice: []

    };
  }, mounted() {
    this.getPairs();
    this.getMarketAccounts();
    this.getAssetsFastEx();
  },
  methods: {
    getPairs() {
      axiosApi().post( 'api/Manage/v1/Pair/GetList' )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.Markets = data.data;
        } );
    },
    TradeDissAllow( id ) {
      axiosApi().post( 'api/Manage/v1/Pair/TradeDissallow?pairId=' + id )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.getPairs();
          Vue.$toast.open( {
            message: 'تغییر وضعیت بازار با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          } );
        } );
    },
    TradeAllow( id ) {
      axiosApi().post( 'api/Manage/v1/Pair/TradeAllow?pairId=' + id )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.getPairs();
          Vue.$toast.open( {
            message: 'تغییر وضعیت بازار با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          } );
        } );
    },
    fastExchangeAutoSet( assetId, status ) {
      axiosApi().post( 'api/Manage/v1/FastExchange/EnableFastExchangeAutoSet', {
        "assetId": assetId,
        "fastExchangeAutoSet": status
      } ).then( ( { data } ) => {
        if ( data[ 'isSuccess' ] === true ) {
          this.getAssetsFastEx();
          Vue.$toast.open( {
            message: 'تغییر وضعیت با موفقیت انجام شد',
            type: 'success',
            position: 'top-right'
          } );
        }
        else
          data.Errors.forEach( ( item ) => {
            Vue.$toast.open( {
              message: item.Description,
              type: 'error',
              position: 'top-right'
            } );
          } );
      } );
    }
    ,
    addPairs() {
      axiosApi().post( 'api/Manage/v1/Pair/Add', {
        "sourceAssetId": this.sourceAssetId,
        "destinationAssetId": this.destinationAssetId,
        "symbol": this.symbol,
        "persianTitle": this.persianTitle,
        "englishTitle": this.englishTitle
      } ).then( ( { data } ) => {
        if ( data[ 'isSuccess' ] === true )
          this.getPairs(),
            Vue.$toast.open( {
              message: 'پیر جدید با موفقیت اضافه گردید ',
              type: 'success',
              position: 'top-right'
            } );
        else
          data.Errors.forEach( ( item ) => {
            Vue.$toast.open( {
              message: item.Description,
              type: 'error',
              position: 'top-right'
            } );
          } );
      } ).catch( () => {
        Vue.$toast.open( {
          message: 'مقدار ارسالی نمیتواند خالی باشد یا مقدار های ارسالی اشتباه است',
          type: 'error',
          position: 'top-right'
        } );
      } );
    },
    updatePair( id ) {
      axiosApi().post( 'api/Manage/v1/Pair/Update', {
        id: id,
        "sourceAssetId": this.sourceAssetId,
        "destinationAssetId": this.destinationAssetId,
        "symbol": this.symbol,
        "persianTitle": this.persianTitle,
        "englishTitle": this.englishTitle
      } ).then( ( { data } ) => {
        if ( data[ 'isSuccess' ] === true )
          this.getPairs(),
            Vue.$toast.open( {
              message: 'پیر  با موفقیت ویرایش شد',
              type: 'success',
              position: 'top-right'
            } );
        else
          data.Errors.forEach( ( item ) => {
            Vue.$toast.open( {
              message: item.Description,
              type: 'error',
              position: 'top-right'
            } );
          } );
      } );
    },
    getPair( id ) {
      this.updateShow = true;
      this.getAsset();
      axiosApi().post( 'api/Manage/v1/Pair/Get', {
        "id": id
      } ).then( ( { data } ) => {
        if ( data[ 'isSuccess' ] === true )
          this.id = data.data.id;
        this.sourceAssetId = data.data.sourceAssetId;
        this.destinationAssetId = data.data.destinationAssetId;
        this.symbol = data.data.symbol;
        this.persianTitle = data.data.persianTitle;
        this.englishTitle = data.data.englishTitle;
      } );
    },
    getAsset() {
      axiosApi().post( 'api/Manage/v1/Asset/GetList' )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.assets = data.data;
        } );
    },
    // openNewPairPopup(){
    //   this.newShow =true
    //   this.getAsset()
    // },
    getMarketAccounts() {
      axiosApi().post( 'api/Manage/v1/MarketAccount/GetList' )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.MarketAccounts = data.data;
        } );
    },
    TradeDissAllowMarket( id ) {
      axiosApi().post( 'api/Manage/v1/MarketAccount/TradeDisallow', {
        id: id
      } )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.getMarketAccounts();
          Vue.$toast.open( {
            message: 'تغییر وضعیت مارکت با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          } );
        } );
    },
    TradeAllowMarket( id ) {
      axiosApi().post( 'api/Manage/v1/MarketAccount/TradeAllow', {
        id: id
      } )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true )
            this.getMarketAccounts();
          Vue.$toast.open( {
            message: 'تغییر وضعیت مارکت با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          } );
        } );
    },
    getAssetsFastEx() {
      axiosApi().post( '/api/Manage/v1/FastExchange/GetFastExchangePriceInToman',
        {
        }
      )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true ) {
            this.fastExList = data.data.assets;
          }
        } );
    },
    getAssetData( id ) {
      axiosApi().post( '/api/Manage/v1/Asset/Get', {
        id: id
      } )
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true ) {
            this.fastExList = [ ...this.fastExList, data.data ];
          }
        } );
    },
    createArrayFastEx( id ) {
      axiosApi().post( '/api/Manage/v1/FastExchange/GetFastExchangePriceInToman', {
        assetId: id
      } ).then( ( { data } ) => {
        if ( data[ 'isSuccess' ] ) {
          this.arrayPrice = data.data;
          this.arrayPrice[ 'id' ] = id;
          this.arrayPriceFastEx = [ ...this.arrayPriceFastEx, this.arrayPrice ];
        }
      } );
    },
    getFastExchangePrice( id ) {
      if ( id === '' )
        return false;

      axiosApi().post( '/api/Manage/v1/FastExchange/GetFastExchangePriceInToman', {
        assetId: id
      } ).then( ( { data } ) => {
        if ( data[ 'isSuccess' ] ) {
          this.fastBuy = data.data.fastExchangeBuyPriceInToman;
          this.fastSell = data.data.fastExchangeSellPriceInToman;
        }
      } );
    },
    showBuyModal( id ) {
      this.getFastExchangePrice( id );
      this.assetId = id;
      this.buyShow = true;
    },
    showSellModal( id ) {
      this.getFastExchangePrice( id );
      this.assetId = id;
      this.sellShow = true;
    },
    SetFastExchangePrice( type, priceInToman ) {
      let self = this
      axiosApi().post( '/api/Manage/v1/FastExchange/SetFastExchangePriceInToman', {
        assetId: this.assetId,
        orderType: type,
        priceInToman: priceInToman
      } ).then( ( { data } ) => {
        
        if ( data[ 'isSuccess' ] ) {
          self.getAssetsFastEx();
          // self.sellShow =  false,
          // self.buyShow = false,
          Vue.$toast.open( {
            message: 'تغییر قیمت  با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          } );
        }
      } );
    },
    getFilterArray( id, type ) {
      var result = this.arrayPriceFastEx.filter( ( item ) => item.id === id );
      this.autoSetFastExchangePrice = result[ 0 ][ 'autoSetFastExchangePrice' ];
      if ( type === 'Buy' )
        return result[ 0 ][ 'fastExchangeBuyPriceInToman' ];
      return result[ 0 ][ 'fastExchangeSellPriceInToman' ];
    }
  }
};
</script>

<style scoped>

</style>